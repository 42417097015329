import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Hr, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"poslugi-ta-cini"} />
		<Helmet>
			<title>
				Study Prosper Hub
			</title>
			<meta name={"description"} content={"Водійські права — це не лише документ, а й свобода пересування, нові можливості та впевненість у собі."} />
			<meta property={"og:title"} content={"Study Prosper Hub"} />
			<meta property={"og:description"} content={"Водійські права — це не лише документ, а й свобода пересування, нові можливості та впевненість у собі."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/67449b3428b5e30023ae6a02/images/Wright-Illustrations-adult-1110x734x0x0x1110x734x1680136260.png?v=2024-11-25T15:51:08.157Z"} />
		</Helmet>
		<Components.Header />
		<Components.Hero12 padding="120px 0 120px 0" />
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
			/>
			<Box padding="20px 20px 20px 20px" md-width="100%" width="33.333%">
				<Text margin="0px 0px 0px 0px" font="--headline2" color="--dark">
					<Strong>
						Наші послуги
					</Strong>
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Курси для початківців
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Якщо ви вперше сідаєте за кермо, ми створимо для вас комфортне та безпечне середовище для навчання. Програма охоплює:
					<br />
					<br />
					<br />
					<br />
					Теоретичний курс (правила дорожнього руху, дорожні знаки, основи безпеки).
					<br />
					<br />
					Практичні заняття (перші поїздки, маневрування, паркування).
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Курси для вдосконалення
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Для тих, хто вже має водійські права, але хоче покращити свої навички. Програма включає:
					<br />
					<br />
					<br />
					<br />
					Водіння у складних умовах (нічний час, ожеледиця, трафік).
					<br />
					<br />
					Опанування нових технологій автомобілів.
					<br />
					<br />
					Особливості водіння в міському середовищі.
				</Text>
			</Box>
			<Box width="33.333%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline3" letter-spacing="-.06em" color="--dark">
					Підготовка до іспитів
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Ми допомагаємо вам не лише скласти теоретичний та практичний іспити, а й підготуватися психологічно. Ви навчитеся керувати авто впевнено та безпечно.
				</Text>
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Ми пропонуємо теоретичний курс у зручному онлайн-форматі. Ви можете вивчати правила дорожнього руху у будь-який зручний для вас час.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					<Strong>
						Ціни та пакети
					</Strong>
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--headline3">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Базовий курс (для початківців)
						<br />
					</Strong>
					20 теоретичних занять.
					<br />
					15 практичних уроків.
					<br />
					<br />
					<Strong>
						Підготовка до іспиту.{"\n"}
					</Strong>
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Ціна:
					</Strong>
					{" "}від 3000 грн.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Розширений курс (для вдосконалення)
						<br />
					</Strong>
					10 практичних уроків.
					<br />
					Робота над конкретними навичками.{"\n"}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Ціна:
					</Strong>
					{" "}від 2000 грн.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Індивідуальні уроки
						<br />
					</Strong>
					1 заняття — 200 грн.
					<br />
					Пакет з 5 занять — 900 грн.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Онлайн-курс
						<br />
					</Strong>
					Повний доступ до теоретичних матеріалів.{"\n"}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Ціна:
					</Strong>
					{" "}1000 грн.
				</Text>
				<Button
					background="--color-secondary"
					margin="1rem 0px 0px 0px"
					type="link"
					text-decoration-line="initial"
					href="/kontakti"
					align-self="flex-start"
				>
					Контакти
				</Button>
			</Box>
		</Section>
		<Components.Faq />
		<Components.Footer>
			<Override slot="link5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});